import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Col,
  Container,
  Row,
  CardHeader,
  CardBody,
  Card,
  CardTitle,
  CardText, Progress, CardSubtitle
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faEdit} from '@fortawesome/free-solid-svg-icons'
import {toast} from 'react-toastify';
import classNames from 'classnames';

import {getConnectionOptions} from "../utilities/url";

import './scss/ChosenAnswers.scss';
import Animation from "./shared/Animation";
import ReactRouteComponent from "../mixins/ReactRouteComponentMixin";
import {Link} from "react-router-dom";

/**
 * @mixes ReactRouteComponent
 */
export default class ChosenAnswers extends ReactRouteComponent(Component) {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }),
  }
  
  constructor(props) {
    super(props);
    
    this.state = {
      dataInitialised: false,
      dataChanged: false,
      dataSaving: false,
      
      exercise: {},
      chosenAnswers: [],
    }
  }
  
  componentDidMount() {
    this.getChosenAnswersData();
  }
  
  render() {
    let {exercise, chosenAnswers} = this.state;
    
    return (
      <Container className="ChosenAnswers">
        <div id="action-bar" className={classNames({expanded: this.state.dataChanged})} color="success">
          <Link to={`/exercise/${exercise.id}`}><Button><FontAwesomeIcon icon={faEdit}/> Edytuj ćwiczenie</Button></Link>
        </div>
        {this.state.dataSaving &&
        <div className="processing-overlay"/>
        }
        <Row>
          <Col>
            {!this.state.dataInitialised && <Alert><FontAwesomeIcon icon={faSpinner} spin/> Wczytuję</Alert>}
            <Animation type="fade" active={this.state.dataInitialised}>
              <div className="stats-header">
                <h1>Statystyki ćwiczenia</h1>
                <h4>{exercise.name}</h4>
              </div>
              <div className="question-stats-list">
                {chosenAnswers.map((question, index) => {
                  let questionCount = question['chosen_count'];
                  let sum = 0;
                  return (
                    <Card key={index} className="question-stats">
                      <CardHeader>
                        <CardTitle>{question.content}</CardTitle>
                        <CardSubtitle>{questionCount} {questionCount === 1 ? 'odpowiedź' : 'odpowiedzi'}</CardSubtitle>
                      </CardHeader>
                      <CardBody>
                        {question.answers.map((answer, index) => {
                          let answerCount = answer['chosen_count'];
                          let progress = 0;
                          if (questionCount > 0) {
                            if (index !== question.answers.length - 1) {
                              progress = (answerCount / questionCount) * 100;
                              sum += progress;
                            } else {
                              progress = 100 - sum;
                            }
                          }
                          
                          return (
                            <CardBody key={index}>
                              <CardText>{answer['content']} <span className="question-answers-count">({answer['chosen_count']} {answer['chosen_count'] === 1 ? 'odpowiedź' : 'odpowiedzi'})</span></CardText>
                              <Progress value={progress}>{answerCount > 0 ? `${answerCount} z ${questionCount}` : ''}</Progress>
                            </CardBody>
                          )
                        })}
                      </CardBody>
                    </Card>
                  )
                })}
              </div>
            </Animation>
          </Col>
        </Row>
      </Container>
    )
  }
  
  //#region Actions
  
  //#endregion
  
  //#region Requests
  getChosenAnswersData = () => {
    let [exerciseId, sessionId, instanceId] = [this.getURLParameter('exerciseId'), this.getURLParameter('sessionId') ,this.getURLParameter('instanceId')]
    window.axios(getConnectionOptions(`answers/chosen/${exerciseId}/${sessionId}/${instanceId}`))
      .then((result) => {
        let data = result.data;
        
        this.setState({
          dataInitialised: true,
          
          exercise: data['exercise'],
          chosenAnswers: data['chosenAnswers'],
        })
      }).catch((error) => {
      // Connection error
      toast.error('Wystąpił problem podczas pobierania danych.');
    })
  }
  //#endregion
}