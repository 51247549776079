import React, {Component} from 'react';
import propTypes from 'prop-types';
import {Col, FormGroup, Input, Label} from "reactstrap";

export default class MemoryAnswer extends Component {
  static propTypes = {
    questionIndex: propTypes.number,
    answerIndex: propTypes.number,
    question: propTypes.object,
    answer: propTypes.object,
    onElementChanged: propTypes.func,
  }

  render() {
    let {questionIndex, answerIndex, answer, onElementChanged} = this.props;

    return <>
      <FormGroup row key="feedback-row">
        <Label for={`answer-parameters-feedback-${questionIndex}`} md={2}>Obrazek:</Label>
        <Col md={10}>
          <Input className="invisible-edit" type="textarea" rows={answer['parameters']['image'].length > 80 ? 2 : 1} id={`answer-parameters-feedback-${questionIndex}`} placeholder="adres obrazka, który pokaże się po wybraniu prawidłowej pary obrazków"
            onChange={onElementChanged('answer', 'edit', {questionIndex, answerIndex, field: 'parameters', subfield: 'image'})}
            value={answer['parameters']['image']} />
        </Col>
      </FormGroup>
    </>
  }
}