import React, {Component} from 'react';
import propTypes from 'prop-types';

import RiseFallAnswer from "./rise-fall/RiseFallAnswer";
import Answer from "./default/Answer";
import AnswerWithFeedback from "./with-feedback/AnswerWithFeedback";
import EmptyAnswer from "./default/EmptyAnswer";
import MemoryAnswer from "./memory/MemoryAnswer";
import MatchAnswer from "./match/MatchAnswer";

export default class AnswerComponent extends Component {
  static propTypes = {
    questionIndex: propTypes.number,
    answerIndex: propTypes.number,
    exerciseType: propTypes.string,
    question: propTypes.object,
    answer: propTypes.object,
    onElementChanged: propTypes.func,
  }
  
  render() {
    if (this.props.exerciseType === "rise_fall") {
      return <RiseFallAnswer questionIndex={this.props.questionIndex} answerIndex={this.props.answerIndex}
        question={this.props.question} answer={this.props.answer} onElementChanged={this.props.onElementChanged}
      />;
    } else if (["train", "elevator", "doors"].includes(this.props.exerciseType)) {
      return <AnswerWithFeedback questionIndex={this.props.questionIndex} answerIndex={this.props.answerIndex}
        question={this.props.question} answer={this.props.answer} onElementChanged={this.props.onElementChanged}
      />;
    } else if (["memory"].includes(this.props.exerciseType)) {
      return <MemoryAnswer questionIndex={this.props.questionIndex} answerIndex={this.props.answerIndex}
        question={this.props.question} answer={this.props.answer} onElementChanged={this.props.onElementChanged}
      />
    } else if (["match"].includes(this.props.exerciseType)) {
      return <MatchAnswer questionIndex={this.props.questionIndex} answerIndex={this.props.answerIndex}
        question={this.props.question} answer={this.props.answer} onElementChanged={this.props.onElementChanged}
      />
    } else if (["choices", "open-question"].includes(this.props.exerciseType)) {
      return <EmptyAnswer/>
    } else {
      return <Answer questionIndex={this.props.questionIndex} answerIndex={this.props.answerIndex}
        question={this.props.question} answer={this.props.answer} onElementChanged={this.props.onElementChanged}
      />;
    }
  }
}