export default class riseFallObjects {
  
  static newQuestion() {
    return {
      content: "",
      parameters: {},
      answers: [
        riseFallObjects.newAnswer()
      ]
    }
  }
  
  static newAnswer() {
    return {
      content: "",
      correct: false,
      parameters: {
        feedback: "",
        parking: false,
      }
    }
  }
}