export default class MatchQuestionObject {

  static newQuestion() {
    return {
      content: "",
      parameters: {
        categories: {
          "0": {
            name: '',
            image: '',
          }
          ,
          "1": {
            name: '',
            image: '',
          }
        }
      },
      answers: [
        MatchQuestionObject.newAnswer()
      ]
    }
  }

  static newAnswer(category = "0") {
    return {
      content: "",
      correct: true,
      parameters: {
        category,
        correctFeedback: '',
        incorrectFeedback: '',
      }
    }
  }
}