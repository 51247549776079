export default class defaultObjects {

  static newQuestion() {
    return {
      content: "",
      parameters: {},
      answers: [
        defaultObjects.newAnswer()
      ]
    }
  }

  static newAnswer() {
    return {
      content: "",
      correct: false,
      parameters: {}
    }
  }
}