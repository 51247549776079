import RiseFallQuestionObject from "./rise-fall/RiseFallQuestionObject";
import QuizQuestionObject from "./quiz/QuizQuestionObject";
import QuestionWithFeedbackObject from "./with-feedback/QuestionWithFeedbackObject";
import DefaultQuestionObject from "./default/DefaultQuestionObject";
import OpenQuestionQuestionObject from "./open-question/OpenQuestionQuestionObject";
import MemoryQuestionObject from "./memory/MemoryQuestionObject";
import MatchQuestionObject from "./match/MatchQuestionObject";

export default class questionObjectFactory {
  static canAddQuestion(exerciseType, questions) {
    if (questions.length > 0 && ['open-question', 'memory', 'match'].includes(exerciseType)) {
      return false;
    } else return !['message'].includes(exerciseType);
  }

  static newQuestion(exerciseType) {
    if (exerciseType === 'rise_fall') {
      return RiseFallQuestionObject.newQuestion();
    } else if (exerciseType === 'quiz') {
      return QuizQuestionObject.newQuestion();
    } else if (['train', 'elevator', 'doors'].includes(exerciseType)) {
      return QuestionWithFeedbackObject.newQuestion();
    } else if (['shooting', 'choices', 'reveal'].includes(exerciseType)) {
      return DefaultQuestionObject.newQuestion();
    } else if (['open-question'].includes(exerciseType)) {
      return OpenQuestionQuestionObject.newQuestion();
    } else if (['memory'].includes(exerciseType)) {
      return MemoryQuestionObject.newQuestion();
    } else if (['match'].includes(exerciseType)) {
      return MatchQuestionObject.newQuestion();
    } else {
      throw new Error("Unknown exercise type");
    }
  }

  static canAddAnswer(exerciseType) {
    return !(['memory', 'quiz', 'open-question'].includes(exerciseType));
  }

  static canMoveAnswer(exerciseType) {
    return !(['memory'].includes(exerciseType))
  }

  static newAnswer(exerciseType) {
    if (exerciseType === 'rise_fall') {
      return RiseFallQuestionObject.newAnswer();
    } else if (exerciseType === 'quiz') {
      return QuizQuestionObject.newAnswer();
    } else if (['train', 'elevator', 'doors'].includes(exerciseType)) {
      return QuestionWithFeedbackObject.newAnswer();
    } else if (['shooting', 'choices', 'reveal'].includes(exerciseType)) {
      return DefaultQuestionObject.newAnswer();
    } else if (['open-question'].includes(exerciseType)) {
      return OpenQuestionQuestionObject.newAnswer();
    } else if (['match'].includes(exerciseType)) {
      return MatchQuestionObject.newAnswer();
    } else {
      throw new Error("Unknown exercise type");
    }
  }
}