import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './utilities/serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import config from 'react-global-configuration';

import './scss/common.scss';

axios.defaults.withCredentials = true;
window.axios = axios;

config.set({
  // Without trailing slash
  serverAddress: 'https://phytopharm.s.sprin.tech',
  // serverAddress: 'https://s4.sprintechlearning.com',
  // serverAddress: 'https://zlotetarasy.s.sprin.tech',
  // serverAddress:  'https://kpmg.s.sprin.tech/',
  // serverAddress: 'http://192.168.0.4:8888',
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
