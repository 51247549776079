import PropTypes from 'prop-types'
import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input} from 'reactstrap';
import WithFormComponentMixin from "../../../mixins/WithFormComponentMixin";

export default class CreateSessionModal extends WithFormComponentMixin(Component) {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,

    onCreate: PropTypes.func.isRequired
  };

  //#region React
  constructor(props) {
    super(props);

    this.state = {
      sessionName: '',
    }
  }

  render() {
    return (
      <Modal className="CreateSessionModal" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader>Tworzenie sesji</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <form onSubmit={this.submitted}>
                <FormGroup>
                  <Label for="sessionName">Nazwa sesji</Label>
                  <Input type="text" name="sessionName" id="sessionName" placeholder="wpisz nazwę tworzonej sesji" onChange={this.handleInputChange}/>
                </FormGroup>
              </form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>Anuluj</Button>
          <Button color="primary" onClick={this.createSession} disabled={!this.state.sessionName}>Utwórz sesję</Button>
        </ModalFooter>
      </Modal>
    )
  }
  //#endregion React

  //#region Actions
  createSession = () => {
    let name = this.state.sessionName;

    this.setState({
      sessionName: '',
    }, () => {
      this.props.toggle();
      this.props.onCreate(name);
    });
  };

  submitted = (event) => {
    event.preventDefault();
    this.createSession();
  }
  //#endregion Actions
}