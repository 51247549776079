export default class QuizQuestionObject {

  static newQuestion() {
    return {
      content: "",
      parameters: {},
      answers: [
        QuizQuestionObject.newAnswer(true),
        QuizQuestionObject.newAnswer(),
        QuizQuestionObject.newAnswer(),
        QuizQuestionObject.newAnswer(),
      ]
    }
  }

  static newAnswer(correct = false) {
    return {
      content: "",
      correct: correct,
      parameters: {}
    }
  }
}