import React from 'react';
import {Col, FormGroup, Input, Label} from "reactstrap";

export default function MessageScreen({exercise, onElementChanged}) {
  let content = exercise['parameters']['content'];
  if (!content) {
    content = '';
  }

  let header = exercise['parameters']['header'];
  if (!header) {
    header = '';
  }

  return <>
    <FormGroup row key="instruction-row">
      <Label for={`exercise-parameters-content`} md={2} className="text-right"><strong>Nagłówek:</strong></Label>
      <Col md={10}>
        <Input className="invisible-edit" type="textarea" rows={content.length > 80 ? 2 : 1} id={`exercise-parameters-instruction`} placeholder="Nagłówek wiadomości"
          onChange={onElementChanged('exercise', 'edit', {field: 'parameters', subfield: 'header'})}
          value={header} />
      </Col>
      <Label for={`exercise-parameters-content`} md={2} className="text-right"><strong>Treść:</strong></Label>
      <Col md={10}>
        <Input className="invisible-edit" type="textarea" rows={content.length > 80 ? 2 : 1} id={`exercise-parameters-instruction`} placeholder="Treść wiadomości"
          onChange={onElementChanged('exercise', 'edit', {field: 'parameters', subfield: 'content'})}
          value={content} />
      </Col>
    </FormGroup>
  </>
}