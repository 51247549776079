export default class MemoryQuestionObject {

  static newQuestion() {
    return {
      content: "",
      parameters: {},
      answers: [
        MemoryQuestionObject.newAnswer(1),
        MemoryQuestionObject.newAnswer(1),
        MemoryQuestionObject.newAnswer(2),
        MemoryQuestionObject.newAnswer(2),
        MemoryQuestionObject.newAnswer(3),
        MemoryQuestionObject.newAnswer(3),
        MemoryQuestionObject.newAnswer(4),
        MemoryQuestionObject.newAnswer(4),
        MemoryQuestionObject.newAnswer(5),
        MemoryQuestionObject.newAnswer(5),
        MemoryQuestionObject.newAnswer(6),
        MemoryQuestionObject.newAnswer(6),
      ]
    }
  }

  static newAnswer(id = 1) {
    return {
      content: "",
      correct: true,
      parameters: {
        id,
        image: '',
      }
    }
  }
}