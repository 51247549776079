import PropTypes from 'prop-types'
import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col} from 'reactstrap';

export default class ConfirmationModal extends Component {
  static propTypes = {
    header: PropTypes.string,
    description: PropTypes.string.isRequired,
    actionName: PropTypes.string.isRequired,

    onConfirmed: PropTypes.func.isRequired,

    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };

  //#region React
  render() {
    return (
      <Modal className="ConfirmationModal" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader>{ this.props.header ? this.props.header : this.props.actionName }</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p>{ this.props.description }</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>Anuluj</Button>
          <Button color="primary" onClick={this.confirm}>{ this.props.actionName }</Button>
        </ModalFooter>
      </Modal>
    )
  }
  //#endregion React

  //#region Actions
  confirm = () => {
    this.props.toggle();
    this.props.onConfirmed();
  };
  //#endregion Actions
}