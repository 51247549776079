import React, {Component} from 'react';
import propTypes from 'prop-types';
import {Col, FormGroup} from "reactstrap";
import classNames from "classnames";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faMinusSquare} from "@fortawesome/free-solid-svg-icons";

export default class Answer extends Component {
  static propTypes = {
    questionIndex: propTypes.number,
    answerIndex: propTypes.number,
    question: propTypes.object,
    answer: propTypes.object,
    onElementChanged: propTypes.func,
  };

  render() {
    let {questionIndex, answerIndex, answer, onElementChanged} = this.props;

    return [
      <FormGroup row key="options-row">
        <Col md={2}>
          <div data-toggleable={true} data-value={answer.correct} className={classNames("clickable", "toggleable", {'correct': answer.correct})}
            onClick={onElementChanged('answer', 'edit', {questionIndex, answerIndex, field: 'correct'})}>
            <FontAwesomeIcon icon={answer.correct ? faCheckSquare : faMinusSquare} size="lg"/> {answer.correct ? 'Prawidłowa' : 'Nieprawidłowa'}
          </div>
        </Col>
      </FormGroup>
    ]
  }
}