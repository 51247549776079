import React, {Component} from 'react';
import propTypes from 'prop-types';
import {Col, FormGroup, Input, Label} from "reactstrap";
import ExerciseWithTime from "../with-time/ExerciseWithTime";

export default class RiseFallExercise extends Component {
  static propTypes = {
    exercise: propTypes.object,
    onElementChanged: propTypes.func,
  };

  render() {
    let {exercise, onElementChanged} = this.props;
    let value = exercise['parameters']['instruction'];
    if (!value) {
      value = '';
    }

    return <>
      <ExerciseWithTime exercise={exercise} onElementChanged={onElementChanged} />
      <FormGroup row key="instruction-row">
        <Label for={`exercise-parameters-instruction`} md={2} className="text-right"><strong>Polecenie:</strong></Label>
        <Col md={10}>
          <Input className="invisible-edit" type="textarea" rows={value.length > 80 ? 2 : 1} id={`exercise-parameters-instruction`} placeholder="Polecenie ćwiczenia"
            onChange={onElementChanged('exercise', 'edit', {field: 'parameters', subfield: 'instruction'})}
            value={value} />
        </Col>
      </FormGroup>
    </>
  }
}