import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Col, Container, Row, CardHeader, Input, CardBody, Card} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faGripVertical, faTrash, faCloudUploadAlt, faChartBar} from '@fortawesome/free-solid-svg-icons'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {toast} from 'react-toastify';

import {getConnectionOptions} from "../utilities/url";
import reorder from "../utilities/reorder";

import './scss/Exercise.scss';
import Animation from "./shared/Animation";
import QuestionComponent from "./exercises/QuestionComponent";
import ReactRouteComponent from "../mixins/ReactRouteComponentMixin";
import AnswerComponent from "./exercises/AnswerComponent";
import {getFieldValue} from "../utilities/form";
import QuestionObjectFactory from "./exercises/QuestionObjectFactory";
import {Link} from "react-router-dom";
import ExerciseComponent from "./exercises/ExerciseComponent";

/**
 * @mixes ReactRouteComponent
 */
export default class Exercise extends ReactRouteComponent(Component) {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }),
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      dataInitialised: false,
      dataChanged: false,
      dataSaving: false,
      
      exercise: {},
      questions: [],
      deleted: {
        questionIds: [],
        answerIds: [],
      }
    }
  }
  
  componentDidMount() {
    this.getExerciseData();
  }
  
  render() {
    return (
      <Container className="Exercise">
        <div id="action-bar">
          <Link to={`/chosen-answers/${this.state.exercise.id}/5/1`}><Button><FontAwesomeIcon icon={faChartBar}/> Statystyki odpowiedzi</Button></Link>
          <Button onClick={this.saveData} disabled={!this.state.dataChanged} color="primary"><FontAwesomeIcon icon={faCloudUploadAlt}/> Zapisz</Button>
        </div>
        { this.state.dataSaving &&
        <div className="processing-overlay" />
        }
        <Row>
          <Col>
            {!this.state.dataInitialised && <Alert><FontAwesomeIcon icon={faSpinner} spin /> Wczytuję</Alert>}
            <Animation type="fade" active={this.state.dataInitialised}>
              <Input className="invisible-edit exercise-title" type="text" id={`exercise-name`} placeholder="Nazwa ćwiczenia"
                onChange={this.changeElement('exercise', 'edit', {field: 'name'})} value={this.state.exercise['name']} />
              <ExerciseComponent exerciseType={this.state.exercise['slug']} exercise={this.state.exercise} onElementChanged={this.changeElement}/>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable-questions" direction="vertical" type="questions">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {this.state.questions.map((question, questionIndex) =>
                        <Draggable index={questionIndex} key={questionIndex} draggableId={`question-${questionIndex}`} >
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <ExerciseQuestion dragHandleProps={provided.dragHandleProps} onElementChanged={this.changeElement}
                                exerciseType={this.state.exercise['slug']} question={question} questionIndex={questionIndex} />
                            </div>
                          )}
                        </Draggable>
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              { QuestionObjectFactory.canAddQuestion(this.state.exercise['slug'], this.state.questions) &&
                <Button color="primary" size="lg" block onClick={this.changeElement('question', 'add')}>Dodaj pytanie</Button>
              }
            </Animation>
          </Col>
        </Row>
      </Container>
    )
  }
  
  //#region Actions
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    
    let questions;
    if (result.type === 'questions') {
      questions = reorder(
        this.state.questions,
        result.source.index,
        result.destination.index
      );
    } else {
      questions = Array.from(this.state.questions)
      for (let [index, question] of Object.entries(questions)) {
        if (result.type === `question-${index}`) {
          question.answers = reorder(
            question.answers,
            result.source.index,
            result.destination.index
          )
        }
      }
    }

    this.setState({
      dataChanged: true,
      questions,
    });
  }
  
  processChangeRequest = (callback) => (event) => {
    if (!this.state.dataSaving) {
      this.setState({
        dataChanged: true,
      });
  
      callback(event);
    }
  }
  
  changeElement = (element, action, parameters = {}) => {
    let {field, subfield, subsubfields, questionIndex, answerIndex} = parameters;
    
    // this.setState({
    //   dataChanged: true,
    // });
    
    
    switch (element) {
      case 'exercise':
        switch (action) {
          ////
          // Exercise
          ////
          case 'edit':
            return this.processChangeRequest(this.editExercise(field, subfield));
          default:
            throw new Error('Wrong action for exercise element');
        }
      case 'question':
        switch (action) {
          ////
          // Question
          ////
          case 'edit':
            return this.processChangeRequest(this.editQuestion(questionIndex, field, subfield, subsubfields));
          case 'delete':
            return this.processChangeRequest(this.deleteQuestion(questionIndex));
          case 'add':
            return this.processChangeRequest(this.addQuestion());
          default:
            throw new Error('Wrong action for question element');
        }
        
      case 'answer':
        switch (action) {
          ////
          // Answer
          ////
          case 'edit':
            return this.processChangeRequest(this.editAnswer(questionIndex, answerIndex, field, subfield));
          case 'delete':
            return this.processChangeRequest(this.deleteAnswer(questionIndex, answerIndex));
          case 'add':
            return this.processChangeRequest(this.addAnswer(questionIndex));
          default:
            throw new Error('Wrong action for answer element');
        }
      default:
        throw new Error('Wrong element');
    }
  }
  
  editExercise = (field, subfield = undefined) => (event) => {
    let newValue = getFieldValue(event.target);
    
    this.setState((state) => {
      if (subfield !== undefined)
        state.exercise[field][subfield] = newValue;
      else
        state.exercise[field] = newValue;
      
      return {
        exercise: state.exercise,
      }
    })
  }
  
  editQuestion = (questionIndex, field, subfield = undefined, subsubfields = undefined) => (event) => {
    let newValue = getFieldValue(event.target);
    this.setState((state) => {
      if (subfield !== undefined)
        if (subsubfields !== undefined) {
          let subsubfield = state.questions[questionIndex][field][subfield];
          let lastField = subsubfields.pop();
          for (field of subsubfields)
            subsubfield = subsubfield[field];
          subsubfield[lastField] = newValue;
        } else
          state.questions[questionIndex][field][subfield] = newValue;
      else
        state.questions[questionIndex][field] = newValue;

      return {
        questions: state.questions
      }
    })
  }
  
  deleteQuestion = (questionIndex) => (event) => {
    this.setState((state) => {
      let questionId = state.questions[questionIndex]['id'];
      
      if (questionId !== undefined) {
        state.deleted.questionIds.push(questionId)
      }
      
    state.questions.splice(questionIndex, 1);
      
      return {
        questions: state.questions,
        deleted: state.deleted,
      }
    })
  }
  
  
  addQuestion = () => (event) => {
    let newQuestion = QuestionObjectFactory.newQuestion(this.state.exercise['slug']);
    this.setState((state) => {
      state.questions.push(newQuestion);
      
      return {
        questions: state.questions,
      }
    })
  }
  
  editAnswer = (questionIndex, answerIndex, field, subfield = undefined) => (event) => {
    let newValue = getFieldValue(event.target);
    
    this.setState((state) => {
      if (subfield !== undefined) {
        state.questions[questionIndex].answers[answerIndex][field][subfield] = newValue;
      } else {
        state.questions[questionIndex].answers[answerIndex][field] = newValue;
      }
      
      return {
        questions: state.questions
      }
    })
  }
  
  deleteAnswer = (questionIndex, answerIndex) => (event) => {
    this.setState((state) => {
      let answerId = state.questions[questionIndex].answers[answerIndex]['id'];
      if (answerId !== undefined) {
        state.deleted.answerIds.push(answerId)
      }
      state.questions[questionIndex].answers.splice(answerIndex, 1);
      
      return {
        questions: state.questions,
        deleted: state.deleted,
      }
    })
  }
  
  addAnswer = (questionIndex) => () => {
    let newAnswer = QuestionObjectFactory.newAnswer(this.state.exercise['slug']);
    this.setState((state) => {
      state.questions[questionIndex].answers.push(newAnswer);
      
      return {
        questions: state.questions,
      }
    })
  }
  
  saveData = () => {
    this.setState({
      dataSaving: true,
    })
    
    this.sendExerciseData();
  }
  //#endregion
  
  //#region Requests
  getExerciseData = () => {
    window.axios(getConnectionOptions(`exercise/${this.getURLParameter('id')}`)).then((result) => {
      let data = result.data;
      
      this.setState({
        dataInitialised: true,
        
        exercise: data['exercise'],
        questions: data['questions'],
      })
    }).catch((error) => {
      // Connection error
      toast.error('Wystąpił problem podczas pobierania danych ćwiczenia.');
    })
  }
  
  sendExerciseData = () => {
    window.axios(getConnectionOptions(
      "exercise",
      "PUT",
      {
        exercise: this.state.exercise,
        questions: this.state.questions,
        deleted: this.state.deleted,
      })
    ).then((result) => {
      // Success
      toast.success('Ćwiczenie zapisane!');
      
      this.setState({
        exercise: result.data.exercise,
        questions: result.data.questions,
        dataSaving: false,
        dataChanged: false,
        deleted: {
          questionIds: [],
          answerIds: [],
        }
      })
    }).catch((error) => {
      // Error
      toast.error('Wystąpił problem podczas zapisywania. Spróbuj ponownie.');
      this.setState({
        dataSaving: false,
      })
    });
  }
  //#endregion
}

class ExerciseQuestion extends Component {
  static propTypes = {
    dragHandleProps: PropTypes.object,
    exerciseType: PropTypes.string,
    question: PropTypes.object,
    questionIndex: PropTypes.number,
    onElementChanged: PropTypes.func,
    
  };
  
  render() {
    let {dragHandleProps, exerciseType, question, questionIndex, onElementChanged} = this.props;
    return (
    <Card className="question" inverse>
      <CardHeader className="question-header" {...dragHandleProps}>
        <Row>
          <Col className="d-flex align-items-center" md={"auto"}>
            <div className="drag-bar"><FontAwesomeIcon icon={faGripVertical}/></div>
          </Col>
          <Col><Input className="invisible-edit" type="textarea" id={`question-content-${questionIndex}`} placeholder="Treść pytania"
            rows={question['content'].length > 60 ? 3 : 1}
            onChange={onElementChanged('question', 'edit', {questionIndex, field: 'content'})} value={question['content']} />
          </Col>
          <Col className="d-flex align-items-center" md={"auto"}>
            <Button className="delete-question" block onClick={onElementChanged('question', 'delete', {questionIndex})}><FontAwesomeIcon icon={faTrash}/></Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <QuestionComponent exerciseType={exerciseType} question={question} questionIndex={questionIndex} onElementChanged={onElementChanged} />
        <Droppable droppableId={`droppable-answers-${questionIndex}`} direction="vertical" type={`question-${questionIndex}`}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {question.answers.map((answer, answerIndex) =>
                <Draggable index={answerIndex} key={answerIndex} draggableId={`answer-${questionIndex}-${answerIndex}`} >
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <Card className="answer">
                        <CardHeader>
                          <Row>
                            {QuestionObjectFactory.canMoveAnswer(exerciseType) &&
                            <Col className="d-flex align-items-center" md={"auto"}>
                              <div className="drag-bar"><FontAwesomeIcon icon={faGripVertical}/></div>
                            </Col>
                            }
                            <Col>
                              <Input className="invisible-edit" type="textarea" rows={answer['content'].length > 80 ? 2 : 1} id={`answer-content-${questionIndex}`} placeholder="Treść odpowiedzi"
                                onChange={onElementChanged('answer', 'edit', {questionIndex, answerIndex, field: 'content'})} value={answer['content']} />
                            </Col>
                            {QuestionObjectFactory.canAddAnswer(exerciseType) &&
                            <Col className="d-flex align-items-center" md="auto">
                              <Button className="delete-question" block onClick={onElementChanged('answer', 'delete', {
                                questionIndex,
                                answerIndex
                              })}><FontAwesomeIcon icon={faTrash}/></Button>
                            </Col>
                            }
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <AnswerComponent exerciseType={exerciseType} answerIndex={answerIndex} questionIndex={questionIndex}
                            question={question} answer={answer} onElementChanged={onElementChanged} key={questionIndex} />
                        </CardBody>
                      </Card>
                    </div>
                  )}
                </Draggable>
              )}
            </div>
          )}
        </Droppable>
        {QuestionObjectFactory.canAddAnswer(exerciseType) &&
        <Button color="secondary" block onClick={onElementChanged('answer', 'add', {questionIndex})}>Dodaj nową odpowiedź</Button>
        }
      </CardBody>
    </Card>
    )
  }
}