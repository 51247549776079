import React, {Component} from 'react';
import propTypes from 'prop-types';
import {Col, FormGroup, Input, Label} from "reactstrap";
import classNames from "classnames";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faMinusSquare} from "@fortawesome/free-solid-svg-icons";

export default class AnswerWithFeedback extends Component {
  static propTypes = {
    questionIndex: propTypes.number,
    answerIndex: propTypes.number,
    question: propTypes.object,
    answer: propTypes.object,
    onElementChanged: propTypes.func,
  }

  render() {
    let {questionIndex, answerIndex, answer, onElementChanged} = this.props;

    return [
      <FormGroup row key="feedback-row">
        <Label for={`answer-parameters-feedback-${questionIndex}`} md={2}>Feedback:</Label>
        <Col md={10}>
          <Input className="invisible-edit" type="textarea" rows={answer['parameters']['feedback'].length > 80 ? 2 : 1} id={`answer-parameters-feedback-${questionIndex}`} placeholder="Informacja zwrotna"
            onChange={onElementChanged('answer', 'edit', {questionIndex, answerIndex, field: 'parameters', subfield: 'feedback'})}
            value={answer['parameters']['feedback']} />
        </Col>
      </FormGroup>,
      <FormGroup row key="options-row">
        <Col md={2}>
          <div data-toggleable={true} data-value={answer.correct} className={classNames("clickable", "toggleable", {'correct': answer.correct})}
            onClick={onElementChanged('answer', 'edit', {questionIndex, answerIndex, field: 'correct'})}>
            <FontAwesomeIcon icon={answer.correct ? faCheckSquare : faMinusSquare} size="lg"/> {answer.correct ? 'Prawidłowa' : 'Nieprawidłowa'}
          </div>
        </Col>
      </FormGroup>
    ]
  }
}