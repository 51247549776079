import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Col, Container, Row, Table} from "reactstrap";
import {Link, Redirect} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faExclamationTriangle, faGripVertical} from '@fortawesome/free-solid-svg-icons'

import {getConnectionOptions} from "../../utilities/url";

import Animation from "../shared/Animation";
import ReactRouteComponent from "../../mixins/ReactRouteComponentMixin";
import AddExerciseModal from "./subcomponents/AddExerciseModal";
import ExerciseTypes from "../../shared/ExerciseTypes";

import './Session.scss';
import ConfirmationModal from "../shared/ConfirmationModal";
import WithModalsComponentMixin from "../../mixins/WithModalsComponentMixin";
import reorder from "../../utilities/reorder";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import classNames from "classnames";

/**
 * @mixes ReactRouteComponent
 */
export default class Session extends WithModalsComponentMixin(ReactRouteComponent(Component)) {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }),
  };

  //#region React
  constructor(props) {
    super(props);

    this.state = Object.assign(this.state, {
      dataInitialised: false,
      disabled: false,
      error: false,
      
      session: {},
      exerciseList: [],
      
      modals: {
        addExercise: false,
        removeExercise: false,
      },

      removedExerciseId: undefined,
      
      redirectToExerciseId: undefined,
    });
  }
  
  componentDidMount() {
    this.getSessionList();
  }
  
  render() {
    if (this.state.redirectToExerciseId !== undefined) {
      return <Redirect push to={`/exercise/${this.state.redirectToExerciseId}`}/>
    }

    let currentComponent;
    if (this.state.error) {
      currentComponent = this.getErrorComponent();
    } else if (!this.state.dataInitialised) {
      currentComponent = this.getLoadingComponent();
    } else {
      currentComponent = this.getTableComponent();
    }
    
    return (
      <Container className="Session">
        <AddExerciseModal toggle={this.toggleModalAction('addExercise')} isOpen={this.state.modals['addExercise']}
                          addExercise={this.addExercise} exerciseTypes={ExerciseTypes}
        />
        <ConfirmationModal header="Usuwanie ćwiczenia z sesji" description={`Czy na pewno chcesz usunąć to ćwiczenie z sesji?`}
                           actionName="Usuń" onConfirmed={this.removeExercise}
                           isOpen={this.state.modals['removeExercise']} toggle={this.toggleModalAction('removeExercise')}/>
        <Row>
          <Col>
            <Animation type="fade" active={this.state.dataInitialised}>
              {currentComponent}
            </Animation>
          </Col>
        </Row>
      </Container>
    )
  }
  //#endregion React

  //#region Rendering
  getErrorComponent = () => {
    return <Alert color="danger"><FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" /> <strong>Błąd:</strong> {this.state.error}</Alert>
  };

  getLoadingComponent = () => {
    return <Alert><FontAwesomeIcon icon={faSpinner} spin className="mr-1" /> Wczytuję</Alert>
  };

  getTableComponent = () => {
    return <div>
      <h1>Edycja sesji "{this.state.session['name']}"</h1>
      <Table hover className={classNames({disabled: this.state.disabled})}>
        <thead>
        <tr>
          <th>#</th>
          <th className="name">Nazwa</th>
          <th>Akcje</th>
        </tr>
        </thead>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable-sessions" direction="vertical" type="sessions">
            {(provided, snapshot) => (
              <tbody
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
              {this.state.exerciseList.map((exercise, index) =>
                <Draggable index={index} key={index} draggableId={`exercise-${index}`} >
                  {(provided, snapshot) => (
                  <tr ref={provided.innerRef} {...provided.draggableProps} key={index} className={classNames({'dragged': snapshot['isDragging']})}>
                    <th {...provided.dragHandleProps} scope="row"><FontAwesomeIcon icon={faGripVertical}/></th>
                    <td className="clickable" onClick={this.redirectToExerciseId.bind(this, exercise.id)}>{ exercise.name }</td>
                    <td className="actions">
                      <Link to={`/exercise/${exercise.id}`}><Button color="primary">Edytuj</Button></Link>
                      <Button color="danger" onClick={this.confirmExerciseRemovalAction(exercise.id)}>Usuń</Button>
                    </td>
                  </tr>
                  )}
                </Draggable>
              )
              }
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
      <Button disabled={this.state.disabled} color="primary" size="lg" onClick={this.toggleModalAction('addExercise')} block>Dodaj nowe ćwiczenie</Button>
    </div>
  };
  //#endregion Rendering

  //#region Actions
  onDragEnd = (result) => {
    // dropped outside the list
    if (this.state.disabled || !result.destination) {
      return;
    }

    let exerciseList = reorder(
      this.state.exerciseList,
      result.source.index,
      result.destination.index
    );


    this.setState({
      exerciseList,
    }, () => {
      this.saveExercisesOrder();
    });
  };

  redirectToExerciseId = (exerciseId) => {
    this.setState({
      redirectToExerciseId: exerciseId,
    })
  };
  
  addExercise = (exerciseInfo) => {
    this.setState({
      disabled: true,
    }, () => {
      window.axios(getConnectionOptions(`session/${this.getURLParameter('id')}/add-exercise`, "POST", {exerciseType: exerciseInfo.type})).then((result) => {
        let data = result.data;

        this.setState({
          disabled: false,

          exerciseList: data['exercises'],
        })
      }).catch((error) => {
        this.setState({
          error: "Nie mogę dodać ćwiczenia."
        });
      })
    });
  };

  removeExercise = () => {
    this.setState({
      disabled: true,
    }, () => {
      window.axios(getConnectionOptions(
        `session/${this.getURLParameter('id')}/remove-exercise`,
        "POST",
        {exerciseId: this.state.removedExerciseId})).then((result) => {
        let data = result.data;

        this.setState({
          disabled: false,

          exerciseList: data['exercises'],
        })
      }).catch((error) => {
        this.setState({
          error: "Nie mogę usunąć ćwiczenia."
        });
      })
    });
  };

  confirmExerciseRemovalAction = (exerciseId) => () => {
    this.setState((state) => {
      state.modals.removeExercise = true;

      return {
        modals: state.modals,
        removedExerciseId: exerciseId,
      };
    });
  };
  //#endregion
  
  //#region Requests
  getSessionList = () => {
    window.axios(getConnectionOptions(`session/${this.getURLParameter('id')}/exercises`)).then((result) => {
      let data = result.data;
      
      this.setState({
        dataInitialised: true,
        
        session: data['session'],
        exerciseList: data['exercises'],
      })
    }).catch((error) => {
      // Connection error
      this.setState({
        error: "Nie mogę załadować informacji o sesji.",
      })
    })
  };

  saveExercisesOrder = () => {
    let exercisesOrder = this.getExercisesOrderList();

    this.setState({
      disabled: true
    }, () => {
      window.axios(getConnectionOptions(
        `session/${this.getURLParameter('id')}/reorder`,
        "POST",
        {exercisesOrder: exercisesOrder})).then((result) => {
        let data = result.data;

        this.setState({
          disabled: false,

          exerciseList: data['exercises'],
        })
      }).catch((error) => {
        this.setState({
          error: "Wystąpił problem podczas zapisywania kolejności ćwiczeń w sesji."
        });
      })
    })
  };

  getExercisesOrderList = () => {
    return this.state.exerciseList.map((exercise) => {
      return exercise['id'];
    })
  }
  //#endregion
}
