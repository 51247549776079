import React, {Component} from 'react';
import propTypes from 'prop-types';
import {Button, ButtonGroup, Col, FormGroup, Input, Label} from "reactstrap";

export default class MatchAnswer extends Component {
  static propTypes = {
    questionIndex: propTypes.number,
    answerIndex: propTypes.number,
    question: propTypes.object,
    answer: propTypes.object,
    onElementChanged: propTypes.func,
  };

  render() {
    let {question, questionIndex, answerIndex, answer, onElementChanged} = this.props;

    return <>
      <FormGroup row>
        <Label for={`answer-parameters-correct-feedback-${questionIndex}`} md={3}>Feedback (prawidłowa):</Label>
        <Col md={9}>
          <Input className="invisible-edit" type="textarea" rows={answer['parameters']['correctFeedback'].length > 80 ? 2 : 1} id={`answer-parameters-correct-feedback-${questionIndex}`} placeholder="Informacja zwrotna (prawidłowa odpowiedź)"
            onChange={onElementChanged('answer', 'edit', {questionIndex, answerIndex, field: 'parameters', subfield: 'correctFeedback'})}
            value={answer['parameters']['correctFeedback']} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for={`answer-parameters-incorrect-feedback-${questionIndex}`} md={3}>Feedback (nieprawidłowa):</Label>
        <Col md={9}>
          <Input className="invisible-edit" type="textarea" rows={answer['parameters']['incorrectFeedback'].length > 80 ? 2 : 1} id={`answer-parameters-incorrect-feedback-${questionIndex}`} placeholder="Informacja zwrotna (nieprawidłowa odpowiedź)"
            onChange={onElementChanged('answer', 'edit', {questionIndex, answerIndex, field: 'parameters', subfield: 'incorrectFeedback'})}
            value={answer['parameters']['incorrectFeedback']} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <ButtonGroup>
            {Object.entries(question.parameters.categories).map(([index, category]) => {
              return <Button data-setter={true} data-value={index} key={index}
                onClick={onElementChanged('answer', 'edit', {questionIndex, answerIndex, field: "parameters", subfield: "category"})}
                color={answer.parameters.category === index ? "success" : "secondary"}
              >
                {category.name}
              </Button>
            })}
          </ButtonGroup>
        </Col>
      </FormGroup>
    </>
  }
}