export default class OpenQuestionQuestionObject {

  static newQuestion() {
    return {
      content: "",
      parameters: {},
      answers: [
        OpenQuestionQuestionObject.newAnswer(),
        OpenQuestionQuestionObject.newAnswer(),
        OpenQuestionQuestionObject.newAnswer(),
        OpenQuestionQuestionObject.newAnswer(),
      ]
    }
  }

  static newAnswer() {
    return {
      content: "",
      parameters: {}
    }
  }
}