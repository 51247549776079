export function getFieldValue(field) {
  // debugger;
  if (field.parentElement.hasAttribute('data-toggleable')) {
    field = field.parentElement;
  }
  
  if (field.hasAttribute('data-toggleable')) {
    if (field.dataset['toggleable']) {
      let value = (field.dataset['value'] === "true");
      return !value;
    }
  } else if (field.hasAttribute('data-setter')) {
    if (field.dataset['setter']) {
      if (field.hasAttribute('data-type')) {
        if (field.dataset['type'] === "number") {
          return parseInt(field.dataset['value']);
        } else if (field.dataset['type'] === "bool") {
          return field.dataset['value'] !== "false";
        }
      } else {
        return field.dataset['value'];
      }
    }
  } else {
    if (field.type === "number") {
      return parseInt(field.value);
    } else {
      return field.value;
    }
  }
}