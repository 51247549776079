export default class QuestionWithFeedbackObject {
  
  static newQuestion() {
    return {
      content: "",
      parameters: {},
      answers: [
        QuestionWithFeedbackObject.newAnswer()
      ]
    }
  }
  
  static newAnswer() {
    return {
      content: "",
      correct: false,
      parameters: {
        feedback: "",
      }
    }
  }
}