import React, {Component} from 'react';
import propTypes from 'prop-types';
import {Button, ButtonGroup, Col, FormGroup, Input, Label} from "reactstrap";
import ExerciseWithTime from "../with-time/ExerciseWithTime";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faMinusSquare} from "@fortawesome/free-solid-svg-icons";

export default function QuizExercise({exercise, onElementChanged}) {
  let value = exercise['parameters']['answersHidden'];
  if (!value) {
    value = false;
  }

  return <>
    <ExerciseWithTime exercise={exercise} onElementChanged={onElementChanged} />
    <FormGroup row>
      <Col>
        <ButtonGroup>
          <Button data-setter={true} data-value={"true"} data-type="bool"
            onClick={onElementChanged('exercise', 'edit', {field: 'parameters', subfield: 'answersHidden'})}
            color={value ? "success" : "secondary"}
          >
            Odpowiedzi zakryte
          </Button>
          <Button data-setter={true} data-value={"false"} data-type="bool"
            onClick={onElementChanged('exercise', 'edit', {field: 'parameters', subfield: 'answersHidden'})}
            color={!value ? "success" : "secondary"}
          >
            Odpowiedzi odkryte
          </Button>
        </ButtonGroup>
      </Col>
    </FormGroup>
  </>
}