import React, {Component} from 'react';
import propTypes from 'prop-types';
import {Col, FormGroup, Input, Label} from "reactstrap";

export default class ExerciseWithTime extends Component {
  static propTypes = {
    exercise: propTypes.object,
    onElementChanged: propTypes.func,
  };

  render() {
    let {exercise, onElementChanged} = this.props;
    let value = exercise['parameters']['timePerQuestionSeconds'];
    if (!value) {
      value = '';
    }

    return <>
      <FormGroup row key="instruction-row">
        <Label for={`exercise-parameters-timePerQuestionSeconds`} md={4}><strong>Czas na odpowiedź na pytanie (w sekundach):</strong></Label>
        <Col md={4}>
          <Input className="invisible-edit" type="number" rows={value.length > 80 ? 2 : 1} id={`exercise-parameters-timePerQuestionSeconds`} placeholder="wpisz liczbę sekund"
            onChange={onElementChanged('exercise', 'edit', {field: 'parameters', subfield: 'timePerQuestionSeconds'})}
            value={value} />
        </Col>
      </FormGroup>
    </>
  }
}