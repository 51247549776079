import React from 'react';
import {Col, FormGroup} from "reactstrap";
import ExerciseWithTime from "../with-time/ExerciseWithTime";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faMinusSquare} from "@fortawesome/free-solid-svg-icons";

export default function ElevatorExercise({exercise, onElementChanged}) {
  let value = exercise['parameters']['automaticMode'];
  if (!value) {
    value = false;
  }

  return <>
    <ExerciseWithTime exercise={exercise} onElementChanged={onElementChanged} />
    <FormGroup row key="options-row" style={{marginBottom: "2rem"}}>
      <Col md={4}>
        <div data-toggleable={true} data-value={value} className={classNames("clickable", "toggleable")}
          onClick={onElementChanged('exercise', 'edit', {field: 'parameters', subfield: 'automaticMode'})}>
          <FontAwesomeIcon icon={value ? faCheckSquare : faMinusSquare} size="lg"/> {value ? 'Automatyczna zmiana piętra' : 'Ręczna zmiana piętra'}
        </div>
      </Col>
    </FormGroup>
  </>
}