import React, {Component} from 'react';
import propTypes from 'prop-types';
import MatchQuestion from "./match/MatchQuestion";

export default class QuestionComponent extends Component {
  static propTypes = {
    exerciseType: propTypes.string,
    question: propTypes.object,
    onElementChanged: propTypes.func,
  };
  
  render() {
    if (["match"].includes(this.props.exerciseType)) {
      return <MatchQuestion
        question={this.props.question}
        questionIndex={this.props.questionIndex}
        onElementChanged={this.props.onElementChanged}
      />;
    } else {
      return ''
    }
  }
}