import React, { Component } from 'react';
import {BrowserRouter, Link, Route} from "react-router-dom";
import {Nav, Navbar, NavItem} from "reactstrap";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import Dashboard from "./components/Dashboard";
import SessionsList from "./components/SessionsList/SessionsList";
import Session from "./components/Session/Session";
import Exercise from "./components/Exercise";

import logoBlue from './images/logo/logo-blue.svg';

import './scss/App.scss';
import ChosenAnswers from "./components/ChosenAnswers";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar id="top-bar" className="top-bar">
            <Link className="navbar-brand" to="/"><img src={logoBlue} alt="SprinTech Logo" /> SprinTech Learning</Link>
          </Navbar>
          <div id="sidebar-container">
            <div id="sidebar">
              <Nav vertical>
                <NavItem>
                  {/*<Link to="/" className="nav-link">Home</Link>*/}
                  <Link to="/session/" className="nav-link">Sesje</Link>
                </NavItem>
              </Nav>
            </div>
          </div>
          <div id="main">
            {/*<Route path="/" exact component={Dashboard} />*/}
            <Route path="/" exact component={SessionsList} />
            <Route path="/session/" exact component={SessionsList} />
            <Route path="/session/:id" component={Session} />
            <Route path="/exercise/:id" component={Exercise} />
            <Route path="/chosen-answers/:exerciseId/:sessionId/:instanceId" component={ChosenAnswers} />
          </div>
          <ToastContainer position="bottom-right" />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
