import PropTypes from 'prop-types'
import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col} from 'reactstrap';
import classNames from 'classnames';

import './AddExerciseModal.scss';

export default class AddExerciseModal extends Component {
  static propTypes = {
    addExercise: PropTypes.func.isRequired,
    exerciseTypes: PropTypes.arrayOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };

  //#region React
  constructor(props) {
    super(props);

    this.state = {
      selected: undefined,
      hovered: undefined,

      adding: false,
    }
  }

  render() {
    const {hovered, selected} = this.state;
    const elementInfo = hovered ? hovered : (selected ? selected : undefined);

    let displayedElement;
    if (elementInfo) {
      displayedElement = (
        <div className="element-info">
          <h3>{elementInfo.name}</h3>
          <p>{elementInfo.description}</p>
        </div>
      )
    } else {
      displayedElement = (
        <div className="element-info">
          <p>Wybierz ćwiczenie po lewej.</p>
        </div>
      );
    }

    return (
      <Modal className="AddExerciseModal" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader>Wybierz ćwiczenie, które chcesz dodać</ModalHeader>
        <ModalBody>
          <Row>
            <Col className="exercise-list" md={3}>
              {this.props.exerciseTypes.map((exercise, index) => {
                return <div key={index}
                            className={classNames('exercise-name', {'selected': this.state.selected && (this.state.selected.type === exercise.type)})}
                            onMouseEnter={this.exerciseHoverAction(exercise, true)} onMouseLeave={this.exerciseHoverAction(exercise, false)}
                            onClick={this.exerciseSelectedAction(exercise)}
                >
                  {exercise.name}
                </div>
              })}
            </Col>
            <Col md={9}>
              {displayedElement}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>Anuluj</Button>
          <Button color="primary" onClick={this.addExercise}
                  disabled={!this.state.selected}>Dodaj ćwiczenie</Button>{' '}
        </ModalFooter>
      </Modal>
    )
  }
  //#endregion React

  //#region Actions
  exerciseSelectedAction = (exercise) => () => {
    this.setState({
      selected: exercise,
    })
  };

  exerciseHoverAction = (exercise, hoverStarted) => () => {
    if (hoverStarted) {
      this.setState({
        hovered: exercise,
      })
    } else if (this.state.hovered.type === exercise.type) {
      this.setState({
        hovered: undefined,
      })
    }
  };

  addExercise = () => {
    let selectedExercise = this.state.selected;

    if (selectedExercise) {
      this.props.addExercise(selectedExercise);
    }

    this.props.toggle();
  };
  //#endregion Actions
}