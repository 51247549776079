import React, {Component} from 'react';
import propTypes from 'prop-types';
import RiseFallExercise from "./rise-fall/RiseFallExercise";
import ExerciseWithTime from "./with-time/ExerciseWithTime";
import ElevatorExercise from "./elevator/ElevatorExercise";
import MessageScreen from "./message/MessageScreen";
import MemoryExercise from "./memory/MemoryExercise";
import QuizExercise from "./quiz/QuizExercise";

export default class ExerciseComponent extends Component {
  static propTypes = {
    exerciseType: propTypes.string,
    exercise: propTypes.object,
    onElementChanged: propTypes.func,
  };

  render() {
    if (this.props.exerciseType === "rise_fall") {
      return <RiseFallExercise
        exercise={this.props.exercise}
        onElementChanged={this.props.onElementChanged}
      />;
    } else if (["doors", "match", "reveal"].includes(this.props.exerciseType)) {
      return <ExerciseWithTime
        exercise={this.props.exercise}
        onElementChanged={this.props.onElementChanged}
      />;
    } else if (["quiz"].includes(this.props.exerciseType)) {
      return <QuizExercise
        exercise={this.props.exercise}
        onElementChanged={this.props.onElementChanged}
      />;
    } else if (["elevator"].includes(this.props.exerciseType)) {
      return <ElevatorExercise
        exercise={this.props.exercise}
        onElementChanged={this.props.onElementChanged}
      />;
    } else if (["message"].includes(this.props.exerciseType)) {
      return <MessageScreen
        exercise={this.props.exercise}
        onElementChanged={this.props.onElementChanged}
      />;
    } else if (["memory"].includes(this.props.exerciseType)) {
      return <MemoryExercise
        exercise={this.props.exercise}
        onElementChanged={this.props.onElementChanged}
      />;
    } else {
      return ''
    }
  };
}