import {Component} from 'react';
import propTypes from 'prop-types';

export default class EmptyAnswer extends Component {
  static propTypes = {
    questionIndex: propTypes.number,
    answerIndex: propTypes.number,
    question: propTypes.object,
    answer: propTypes.object,
    onElementChanged: propTypes.func,
  };

  render() {
    return ''
  }
}