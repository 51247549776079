import React from 'react';
import {Col, FormGroup, Input} from "reactstrap";

export default function MatchQuestion({question, questionIndex, onElementChanged}) {
  return [
    <FormGroup row key="options-row">
      {[0, 1].map(index =>
        <Col md={6} key={index}>
          <Input className="invisible-edit" type="textarea" id={`category-name-${index}`} rows="1"
            placeholder={`nazwa kategorii nr ${index + 1}`}
            onChange={onElementChanged('question', 'edit', {questionIndex, field: 'parameters', subfield: 'categories', subsubfields: [index, "name"]})}
            value={question['parameters']['categories'][index]['name']}
          />
          <Input className="invisible-edit" type="textarea" id={`category-image-${index}`} rows="1"
            placeholder={`obrazek dla kategorii nr ${index + 1}`}
            onChange={onElementChanged('question', 'edit', {questionIndex, field: 'parameters', subfield: 'categories', subsubfields: [index, "image"]})}
            value={question['parameters']['categories'][index]['image']} />
        </Col>
      )}
    </FormGroup>
  ]

}