import React, { Component } from 'react';
import {Alert, Button, Col, Container, Row, Table} from "reactstrap";
import { Redirect } from 'react-router-dom';
import config from 'react-global-configuration';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faMedal,
  faSpinner,
  faExclamationTriangle,
  faGripVertical
} from "@fortawesome/free-solid-svg-icons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import classNames from 'classnames';

import {getConnectionOptions} from "../../utilities/url";

import './SessionsList.scss';
import WithModalsComponentMixin from "../../mixins/WithModalsComponentMixin";
import CreateSessionModal from "./subcomponents/CreateSessionModal";
import ConfirmationModal from "../shared/ConfirmationModal";
import reorder from "../../utilities/reorder";

const INSTANCE_ID = 1;

export default class SessionsList extends WithModalsComponentMixin(Component) {

  constructor(props) {
    super(props);

    this.state = Object.assign(this.state, {
      dataInitialised: false,
      disabled: false,
      modals: {
        createSession: false,
        removeSession: false,
      },
      removedSessionId: false,

      error: false,

      sessionList: [],

      redirectToSessionId: undefined,
    });
  }

  //region React
  componentDidMount() {
    this.getSessionList();
  }

  render() {
    let component;
    let redirectComponent = this.getRedirectComponent();
    if (redirectComponent) {
      component = redirectComponent;
    } else if (this.state.error) {
      component = this.getErrorComponent();
    } else if (!this.state.dataInitialised){
      component = this.getLoadingComponent();
    } else {
      component = this.getTableComponent();
    }

    return (
      <Container className="SessionsList">
        <div id="action-bar">
          <a href={`${config.get('serverAddress')}/admin/change-session/${INSTANCE_ID}/`} target="_blank" rel="noopener noreferrer"><Button color="primary"><FontAwesomeIcon icon={faTachometerAlt}/> Pilot</Button></a>
          <a href={`${config.get('serverAddress')}/admin/ranking/${INSTANCE_ID}`} target="_blank" rel="noopener noreferrer"><Button color="primary"><FontAwesomeIcon icon={faMedal}/> Ranking</Button></a>
        </div>
        {this.getModalComponents()}
        <Row>
          <Col>
            { component }
          </Col>
        </Row>
      </Container>
    )
  }
  //#endregion React

  //#region Rendering
  getModalComponents = () => {
    return (
      <div className="session-list-modals">
        <CreateSessionModal isOpen={this.state.modals['createSession']} toggle={this.toggleModalAction('createSession')} onCreate={this.createSession}/>
        <ConfirmationModal header="Usuwanie sesji" description={`Czy na pewno chcesz usunąć tę sesję?`}
          actionName="Usuń" onConfirmed={this.removeSession}
          isOpen={this.state.modals['removeSession']} toggle={this.toggleModalAction('removeSession')}/>
      </div>
    );
  };

  getRedirectComponent = () => {
    if (this.state.redirectToSessionId !== undefined) {
      return (
        <Redirect push to={`/session/${this.state.redirectToSessionId}`} />
      );
    } else {
      return false;
    }
  };

    getLoadingComponent = () => {
      return (
        <Alert>
          <FontAwesomeIcon icon={faSpinner} spin /> Wczytuję
        </Alert>
      );
    };

  getErrorComponent = () => {
    return (
      <Alert color="danger">
        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" /> <strong>Błąd:</strong> {this.state.error}
      </Alert>
    );
  };

  getTableComponent = () => {
    return (
      <div className="session-table-container">
        <h1>Lista sprintów</h1>
        <Table hover className={classNames({disabled: this.state.disabled})}>
          <thead>
          <tr>
          <th>#</th>
          <th className="name">Nazwa</th>
            <th>Akcje</th>
            </tr>
          </thead>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable-sessions" direction="vertical" type="sessions">
              {(provided, snapshot) => (
                <tbody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                {this.state.sessionList.map((session, index) =>
                  <Draggable index={index} key={index} draggableId={`session-${index}`} >
                    {(provided, snapshot) => (
                      <tr ref={provided.innerRef} {...provided.draggableProps} key={index} className={classNames({'dragged': snapshot['isDragging']})}>
                        <th {...provided.dragHandleProps} scope="row"><FontAwesomeIcon icon={faGripVertical}/></th>
                        <td className="clickable" onClick={this.redirectToSessionId.bind(this, session['session_id'])}><Link to={`/session/${session['session_id']}`}>{ session.name }</Link></td>
                        <td className="actions">
                          <Link to={`/session/${session['session_id']}`}><Button color="primary">Edytuj</Button></Link>
                          <Button color="danger" onClick={this.confirmSessionRemovalAction(session['session_id'])}>Usuń</Button>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                )
                }
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
          {/*<tbody>*/}

          {/*</tbody>*/}
        </Table>
        <Button disabled={this.state.disabled} color="primary" size="lg" onClick={this.toggleModalAction('createSession')} block>Utwórz nową sesję</Button>
      </div>
    );
  };
  //#endregion Rendering

  //#region Actions
  onDragEnd = (result) => {
    // dropped outside the list
    if (this.state.disabled || !result.destination) {
      return;
    }

    let sessionList = reorder(
        this.state.sessionList,
        result.source.index,
        result.destination.index
      );


    this.setState({
      sessionList,
    }, () => {
      this.saveSessionOrder();
    });
  };

  redirectToSessionId = (id) => {
    this.setState({
      redirectToSessionId: id
    })
  };

  confirmSessionRemovalAction = (sessionId) => () => {
    this.setState((state) => {
      state.modals.removeSession = true;

      return {
        modals: state.modals,
        removedSessionId: sessionId,
      };
    });
  };
  //#endregion

  //#region Requests
  removeSession = () => {
    this.setState({
      disabled: true,
    }, () => {
      window.axios(getConnectionOptions(
        `session/${this.state.removedSessionId}`,
        "DELETE",
        {instanceId: INSTANCE_ID})).then((result) => {
        let data = result.data;

        this.setState({
          disabled: false,

          sessionList: data['sessions'],
        })
      }).catch((error) => {
        this.setState({
          error: "Nie mogę usunąć sesji."
        });
      })
    });
  };

  createSession = (sessionName) => {
    this.setState({
      disabled: true,
    }, () => {
      window.axios(getConnectionOptions(
        `session`,
        "POST",
        {instanceId: INSTANCE_ID, sessionName: sessionName})).then((result) => {
        let data = result.data;

        this.setState({
          disabled: false,

          sessionList: data['sessions'],
        })
      }).catch((error) => {
        this.setState({
          error: "Nie mogę utworzyć sesji."
        });
      })
    });
  };

  getSessionList = () => {
    window.axios(getConnectionOptions(`session/list/${INSTANCE_ID}`))
    .then((result) => {
      let data = result.data;

      this.setState({
        sessionList: data['sessions'],
        dataInitialised: true,
      })
    }).catch((error) => {
      // Connection error
      console.log('error');
    })
  };

  saveSessionOrder = () => {
    let sessionOrder = this.getSessionOrderList();

    this.setState({
      disabled: true
    }, () => {
      window.axios(getConnectionOptions(
        `session/reorder`,
        "POST",
        {instanceId: INSTANCE_ID, sessionOrder})).then((result) => {
        let data = result.data;

        this.setState({
          disabled: false,

          sessionList: data['sessions'],
        })
      }).catch((error) => {
        this.setState({
          error: "Wystąpił problem podczas zapisywania kolejności sesji."
        });
      })
    })
  };

  getSessionOrderList = () => {
    return this.state.sessionList.map((session, index) => {
      return session['session_id'];
    })
  }
  //#endregion
}